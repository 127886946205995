// WhySwivo.js
import React from 'react';
import './WhySwivo.css';
import wideVariety from '../../assets/wide.png';
import easysearch from '../../assets/search.png';
import transparent from '../../assets/trans.png';
import directcom from '../../assets/direct.png';
import securebook from '../../assets/secure.png';
import supportservice from '../../assets/supp.png';

const WhySwivo = () => {
  return (
    <div className="why-swivo-page">
      <div className="why-swivo-container">
        <h2>Why Choose Swivospace?</h2>
        <p className="swivo-description">
          Swivospace helps event coordinators easily find and book unique venues, simplifying the process with seamless communication and reliable event solutions.
        </p>
        <div className="swivo-features-row">
          <div className="swivo-feature-item">
            <img
              src={wideVariety}
              alt="Wide Variety of Spaces"
            />
            <p>Wide Variety of Spaces</p>
            <span>From elegant event halls to quirky spaces, we have unique options that cater to all needs.</span>
          </div>
          <div className="swivo-feature-item">
            <img
              src={easysearch}
              alt="Easy Search and Filter Options"
            />
            <p>Catered to Your Needs </p>
            <span>Find your perfect space by location, capacity, type, and amenities.</span>
          </div>
          <div className="swivo-feature-item">
            <img
              src={transparent}
              alt="Transparent Pricing and Availability"
            />
            <p> Transparent Pricing</p>
            <span>Clear pricing upfront, with no hidden fees.</span>
          </div>
        </div>

        <div className="swivo-features-row">
          <div className="swivo-feature-item">
            <img
              src={directcom}
              alt="Direct Communication with Space Owners"
            />
            <p>Direct Communication </p>
            <span>Get in touch directly with venue owners before, during, and after your booking.</span>
          </div>
          <div className="swivo-feature-item">
            <img
              src={securebook}
              alt="Secure Booking Process"
            />
            <p>Secure Booking Process</p>
            <span>Book with confidence knowing your personal information is protected.
            </span>
          </div>
          <div className="swivo-feature-item">
            <img
              src={supportservice}
              alt="Dedicated Support Services"
            />
            <p>Support </p>
            <span>Throughout your experience, we're here for you.</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhySwivo;