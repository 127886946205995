import React from 'react';
import { func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockContainer from '../BlockContainer';

import css from './BlockDefault.module.css';
import HomeBannerSearch from '../../../../components/HomePageBannerSearch/HomePageBannerSearch';
import { GetToKnowUs } from '../../../../components';
import LetUsKnowForm from '../../../../forms/LetUsKnowForm/LetUsKnowForm';
import EventsForm from '../../../../forms/EventsForm/EventsForm';
import Blog from '../../../BlogPage/blog';
import Blog2 from '../../../BlogPage/blog2';



const FieldMedia = props => {
  const { className, media, sizes, options } = props;
  const hasMediaField = hasDataInFields([media], options);
  return hasMediaField ? (
    <div className={classNames(className, css.media)}>
      <Field data={media} sizes={sizes} options={options} />
    </div>
  ) : null;
};

const BlockDefault = props => {
  const {
    blockId,
    className,
    rootClassName,
    mediaClassName,
    textClassName,
    ctaButtonClass,
    title,
    text,
    callToAction,
    media,
    responsiveImageSizes,
    options,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const hasTextComponentFields = hasDataInFields([title, text, callToAction], options);

  

  if (blockId === 'concern') { // Ensure this matches the ID in Sharetribe
    return <LetUsKnowForm />;
  }

  if (blockId === 'eventsform') { // Ensure this matches the ID in Sharetribe
    return <EventsForm />;
  }
  if (blockId === 'blog') { // Ensure this matches the ID in Sharetribe
    return <Blog/>;
  }
  if (blockId === 'blog2') { // Ensure this matches the ID in Sharetribe
    return <Blog2/>;
  }


  

  if (callToAction?.content === 'HOME_PAGE_SEARCH_ONLY_TAG_DNC') {
    return <HomeBannerSearch />;
  } else if (callToAction?.content === 'GET_US_KNOW_PAGE_FORM') {
    return <GetToKnowUs />;
  }
  return (
    <BlockContainer id={blockId} className={classes}>
      <FieldMedia
        media={media}
        sizes={responsiveImageSizes}
        className={mediaClassName}
        options={options}
      />
      {hasTextComponentFields ? (
        <div className={classNames(textClassName, css.text)}>
          <Field data={title} options={options} />
          <Field data={text} options={options} />
          <Field data={callToAction} className={ctaButtonClass} options={options} />
        </div>
      ) : null}
    </BlockContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

BlockDefault.defaultProps = {
  className: null,
  rootClassName: null,
  mediaClassName: null,
  textClassName: null,
  ctaButtonClass: null,
  title: null,
  text: null,
  callToAction: null,
  media: null,
  responsiveImageSizes: null,
  options: null,
};

BlockDefault.propTypes = {
  blockId: string.isRequired,
  className: string,
  rootClassName: string,
  mediaClassName: string,
  textClassName: string,
  ctaButtonClass: string,
  title: object,
  text: object,
  callToAction: object,
  media: object,
  responsiveImageSizes: string,
  options: propTypeOption,
};

export default BlockDefault;