import React from "react";
import "./ChooseUs2.css";
import easyListingImage from "../../assets/easy listing.png";
import accessImage from "../../assets/access-to-wide-range.png";
import insuranceImage from "../../assets/insurance-coverage.png";
import secureImage from "../../assets/secure-payment.png";
import flexibleImage from "../../assets/flexible-rental.png";
import supportImage from "../../assets/support-for-commercial.png";


const WhyChooseUs2 = () => {
  const features = [
    {
      img: easyListingImage,
      title: "Easy Listing Process",
      description:
        "Quickly list your space with our intuitive platform. Upload photos, set descriptions, and customize availability in just a few clicks.",
    },
    {
      img: accessImage,
      title: "Access to a Wide Range of Potential Renters",
      description:
        "Connect with diverse renters seeking office spaces, creative venues, meeting rooms, and more. Expand your reach through our active user base.",
    },
    {
      img: insuranceImage,
      title: "Insurance Coverage",
      description:
        "Partner with trusted insurance providers for comprehensive rental coverage. Get protection against damages and liability, giving you peace of mind.",
    },
    {
      img: secureImage,
      title: "Secure Payment System",
      description:
        "Receive secure, encrypted payments directly to your account with transparent transaction records. We handle the payment processing so you can focus on your space.",
    },
    {
      img: flexibleImage,
      title: "Flexible Rental Options",
      description:
        "Offer hourly, daily, or weekly rentals. Accommodate various events from pop-ups to long-term bookings.",
    },
    {
      img: supportImage,
      title: "Support for Commercial and Non-Traditional Spaces",
      description:
        "List any space type, from offices and retail to studios and warehouses. Our versatile platform welcomes both traditional and unique venues.",
    },
  ];

  return (
    <div className="WhyChooseUs2">
      <div className="container">
        <h1 className="title">Why Choose Swivospace?</h1>
        <p className="description">
          Swivospace connects space owners with those seeking unique event venues.
          Our platform streamlines listing and booking,
          making it easy to find the perfect space.
        </p>
        <div className="features">
          {features.map((feature, index) => (
            <div className="feature-item" key={index}>
              <img src={feature.img} alt={feature.title} className="feature-image" />
              <h2 className="feature-title">{feature.title}</h2>
              <p className="feature-description">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs2;