import React from 'react';
import './Benefits.css';
import showcaseImage from '../../assets/benefits1.jpeg';
import listingImage from '../../assets/benefits2.png';
import earningImage from '../../assets/benefits3.png';

const Benefits = () => {
  return (
    <div className="benefits-section">
      <h2 className="benefits-heading">How it Works</h2>
      <div className="benefits-container">
        <div className="benefit">
          <img src={showcaseImage} alt="Showcase Your Space" className="benefit-icon" />
          <h3 className="benefit-title">Find Your Space</h3>
          <p className="benefit-description">
            Browse through our diverse spaces based on your criteria.
          </p>
        </div>
        <div className="benefit">
          <img src={listingImage} alt="Create a Listing" className="benefit-icon" />
          <h3 className="benefit-title">Book Your Space</h3>
          <p className="benefit-description">
            Evaluate event details and needs, make special requests with the host, and book your space.
          </p>
        </div>
        <div className="benefit">
          <img src={earningImage} alt="Start Earning" className="benefit-icon" />
          <h3 className="benefit-title">Host Your Event</h3>
          <p className="benefit-description">
          You've done all the hard work in finding the perfect space and coordinating the event. It's time to enjoy the occasion!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
